/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "First things first (I’m the realest), second things second, welcome to our blog! We wanted to make our first post a little introduction to our brand, its creation and the meaning behind the name."), "\n", React.createElement(_components.p, null, "If you google Anxious Extrovert, besides a few blog posts, it’s predominantly people asking the internet if they can be an extrovert with anxiety. If you too googled that question and it led you here, the answer is yes you can. Our founder Sam also asked himself this question. As someone who’s a self-confessed social butterfly, he questioned how he could be so outgoing and extroverted and yet have symptoms of anxiety. This led him to initially dismiss his feelings, but more on that another time. Long story short, it was Sam’s personal experience of mental health problems that inspired the creation of the brand and its name. "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "We’re often led to assume a person with mental health issues will be very obvious to spot: quiet and reserved or crazy and out of control. We’re here to remind you that everybody has mental health, so everybody can suffer from poor mental health/mental health illnesses and as such there is no one size fits all profile. Anxious Extrovert may be seen as two polar opposite words, but we’re here to challenge that. There’s currently 5 of us on Team Anxious Extrovert, including an extrovert with anxiety and an introvert with anxiety who loves performing on stage but hates being the centre of attention (you’ll meet us all in another post). We’ll say it one more time for the people at the back, mental health affects EVERYONE and there is NO one size fits all. "), "\n", React.createElement(_components.p, null, "So if you identify as an introvert or an extrovert, if you’ve suffered/ are suffering from poor mental health, know someone who has suffered/is struggling with their mental health or are just interested in reducing the stigma, hearing some real life stories & joining a supportive and non-judgemental community - Anxious Extrovert is for you. "), "\n", React.createElement(_components.p, null, "Our brand comes from a place of passion not profession. If you are in need of professional support, please see the list of help and resources on our website or in our Instagram bio (@anxious_extroverts) or call 999 (or your local emergency number) if you are in immediate danger. Thank you for taking your time to read this post and welcome to those joining our community!"), "\n", React.createElement(_components.p, null, "Until next time,"), "\n", React.createElement(_components.p, null, "Team Anxious Extrovert."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
